import axios from "axios";

const url_public = process.env.VUE_APP_BASE_URL_PUBLIC_BACKEND

 const actions = {
     async get_incident_list({commit, getters}){
         try {
             const dni_search = getters["get_dni_search"];

             const {data} = await axios.get(`${url_public}incidents/incidentList/?dni=${dni_search}`);

             commit("set_incident",data);

             return true;
         }catch (e){
             console.log(e)
             return null;
         }

     },

     async create_incident({commit}, payload){
         try {

             const { data } = await axios.post(`${url_public}incidents/incidentCreate/`, payload);

             commit('set_incident', data);

             return data;
         }catch (e){
             return e.response.data;
         }
     },

     async update_incident({commit}, payload){
            try {

                const {data} = await axios.put(`${url_public}incidents/incident/${payload.id}/`, payload);

                commit('set_incident', data);

                return true;
            }catch (e){
                console.log(e)
                return null;
            }
     },

     async delete_incident({commit}, id){
            try {
                const {data} = await axios.delete(`${url_public}incidents/incident/${id}/`);

                commit('set_incident', data);

                return true;
            }catch (e){
                console.log(e)
                return null;
            }
     }

 }
 export default actions;