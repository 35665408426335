const getters = {
  get_postulante: (state) => {
    return state.postulante_data;
  },

  get_data_created_modified: (state) => {
    return state.data_created_modified;
  },

};
export default getters;
