import axios_public from "@/plugins/axios_db_public";

const actions = {
    async uploadSchools({ commit }, payload) {
        try {

            const response  = await axios_public.post("schools/upload-school/", payload);
            console.log('response', response)
            commit("set_schools", []);
            return response.data ;
        } catch (error) {

            return error.response.data;
        }
    },

    async getListSchools({ commit, getters }) {
        try {
            const textSearch = getters.get_textSearch;

            const { data } = await axios_public.get(`schools/list-schools/?name=${textSearch}`);

            commit("set_schools", data);
            return true
        } catch (error) {
           return null
        }
    },

    async createSchool({ commit }, payload) {
        try {
            const {data} = await axios_public.post("schools/create-school/", payload);

            commit("set_schools", []);

            return data;
        } catch (e) {
            console.log('error', e.response.data)
            return e.response.data;
        }
    },

    async updateSchool({ commit }, payload) {
        try {
            const {data} = await axios_public.put(`schools/update-school/${payload.id}/`, payload);
            commit("set_schools", []);
            return data;
        } catch (e) {
            return e.response.data;
        }
    },

    async deleteSchool({ commit }, id) {
        try {
            await axios_public.delete(`schools/delete-school/${id}/`);
            commit("set_schools", []);
            return true;
        } catch (e) {
            return false
        }
    },

}
export default actions;