const mutations = {
    set_schools(state, schools) {
        state.schoolList = schools;
    },
    set_textSearch(state, textSearch) {
        state.textSearch = textSearch;
    },
    set_selectSchool(state, selectSchool) {
        state.selectSchool = selectSchool;
    },
    set_openDialogForm(state, openDialogForm) {
        state.openDialogForm = openDialogForm;
    },

    set_openDialogDelete(state, openDialogDelete) {
        state.openDialogDelete = openDialogDelete;
    },

}
export default mutations;