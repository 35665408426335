const getters = {
    get_school: (state) => {
        return state.schoolList;
    },
    get_textSearch: (state) => {
        return state.textSearch;
    },
    get_selectSchool: (state) => {
        return state.selectSchool;
    },
    get_openDialogForm: (state) => {
        return state.openDialogForm;
    },

    get_openDialogDelete: (state) => {
        return state.openDialogDelete;
    },

}

export default getters;