const mutations = {
    set_incident(state, data) {
        state.incident_list = data;
    },

    set_dni_search(state, data){
        state.dni_search = data
    },

    set_selected_incident(state, data){
        state.selected_incident = data
    }

}

export default mutations;